import $ from 'jquery';
import 'slick-carousel';

export default () => {



  $(() => {
    
    // jQuery Code Here
    $(".mainvisual").slick({
      dots: true,
      arrows: true,
      centerMode: true,
      centerPadding: '20%',
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnFocus: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '0'
          }
        }
      ]
    });

    
    //product page
    $('.productImages__main').slick({
      dots: false,
      arrows: true,
    });
    $('.productImages__thumbnails__item').on('click', function () {
      const index = $(this).attr('data-index');
      $('.productImages__main').slick('slickGoTo', index);
      setThumbnailsCurrentState($(this), index);
    });
    $(document).on('click', '.l-cartinSku__figure', function () {
      const imgSrc = $(this).find('img').attr('src');
      const index = $(`.productImages__main__inner > img[src="${ imgSrc }"]`).attr('data-index');
      $('.productImages__main').slick('slickGoTo', index);
      setThumbnailsCurrentState($(this), index);
    });

    function setThumbnailsCurrentState ($clickedElement: JQuery<HTMLElement>, index: string) {
      const $productImages__thumbnails__item = $clickedElement.closest('.productMainSection').find('.productImages__thumbnails__item');
      $productImages__thumbnails__item.removeClass('is-current');
      $productImages__thumbnails__item.filter(`[data-index="${ index }"]`).addClass('is-current');
    }







  });




}